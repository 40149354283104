<template>
  <div
    class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[100px] h-[37px] relative gap-1 p-2.5 rounded-lg bg-[#292929] cursor-pointer hover:bg-gray-700"
  >
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="flex-grow-0 flex-shrink-0 w-[18px] h-[18px] relative"
      preserveAspectRatio="none"
    >
      <g clip-path="url(#clip0_1838_4185)">
        <path
          d="M16.6138 6.66732H17.4987C18.4192 6.66732 19.1654 7.41351 19.1654 8.33398V11.6673C19.1654 12.5878 18.4192 13.334 17.4987 13.334H16.6138C16.2037 16.6226 13.3984 19.1673 9.9987 19.1673V17.5007C12.7601 17.5007 14.9987 15.2621 14.9987 12.5007V7.50065C14.9987 4.73923 12.7601 2.50065 9.9987 2.50065C7.23727 2.50065 4.9987 4.73923 4.9987 7.50065V13.334H2.4987C1.57822 13.334 0.832031 12.5878 0.832031 11.6673V8.33398C0.832031 7.41351 1.57822 6.66732 2.4987 6.66732H3.38361C3.79369 3.37873 6.59901 0.833984 9.9987 0.833984C13.3984 0.833984 16.2037 3.37873 16.6138 6.66732ZM2.4987 8.33398V11.6673H3.33203V8.33398H2.4987ZM16.6654 8.33398V11.6673H17.4987V8.33398H16.6654ZM6.4649 13.1547L7.34835 11.7412C8.11671 12.2225 9.0252 12.5007 9.9987 12.5007C10.9722 12.5007 11.8807 12.2225 12.649 11.7412L13.5325 13.1547C12.508 13.7964 11.2967 14.1673 9.9987 14.1673C8.7007 14.1673 7.48938 13.7964 6.4649 13.1547Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1838_4185">
          <rect width="19" height="19" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
    <p
      class="flex-grow-0 flex-shrink-0 text-[13px] font-bold text-left text-white"
    >
      문의하기
    </p>
  </div>
</template>

<script>
export default {}
</script>
