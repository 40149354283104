import { createApp } from 'vue'
import App from './App.vue'
import router from '@/routes'
import store from '@/store'
import '../public/assets/tailwind.css'
import VueGtag from 'vue-gtag'
import * as Sentry from '@sentry/vue'
import sha256 from 'crypto-js/sha256'

const app = createApp(App)
const userEmail = localStorage.getItem('userEmail')
const hashedEmail = userEmail ? sha256(userEmail).toString() : null

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration({ router })],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ['localhost', /^https:\/\/ssdam\.ink\//],
  environment: process.env.VUE_APP_NODE_ENV,
})

app
  .use(router)
  .use(store)
  .use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_MEASUREMENT_ID,
        user_id: hashedEmail,
      },
    },
    router
  )
  .mount('#app')

export const gtag = app.config.globalProperties.$gtag
